import { useDeleteApiService, useGetApiService, usePatchApiService, usePostApiService } from './use-api-service'
import { useStreamService } from './use-stream-service'

export const AUTH_PATH = 'auth'
const CHAT_PATH = 'chat'
const COMPANY_PATH = 'company'
const MEMORY_PATH = 'memory'
const SLACK_PATH = 'slack'
export const LOGOUT_RESOURCE = 'logout'

export interface RegisterPayload {
  name: string
  email: string
  password: string
}

export interface VerificationPayload {
  confirmationCode: string
  email: string
}

export interface LoginPayload {
  email: string
  password: string
}

export interface ForgotPayload {
  email: string
}

export interface GoogleLoginPayload {
  code: string
}

export interface ChatPayload {
  message: string
  threadId?: string
}

export interface RecoverPayload {
  confirmationCode: string
  email: string
  newPassword: string
}

export interface MessageResponse {
  message: string
}

export interface LoginResponse extends MessageResponse {
  name: string
  email: string
  id: string
  companyIds: string[]
}

export interface AuthResponse extends MessageResponse {
  name: string
  email: string
  id: string
  companyIds: string[]
}

export enum ClientType {
  slack = 'slack',
  web = 'web',
}

export interface ActiveUser {
  email: string
  name: string
  blocked: boolean
  client: ClientType
}

export interface Company {
  id: string
  name: string
  employees: number
  industry: string
  activeUsers: ActiveUser[]
  slackInstallationId: string
}

export interface GetCompanyResponse extends MessageResponse {
  company: Company
  slackInstallUrl: string
}

export enum MemoryType {
  vulnerability = 'vulnerability',
  information = 'information',
  process = 'process',
  infraestructure = 'infraestructure',
  securityEvent = 'security-event',
}

export interface Memory {
  id: number
  description: string
  date: string
  type: MemoryType
  context: string
}

export interface GetMemoriesResponse extends MessageResponse {
  memories: Memory[]
}

export const useOboardingService = () => {
  const register = usePostApiService<RegisterPayload, MessageResponse>(AUTH_PATH, 'signup', false)
  const verification = usePostApiService<VerificationPayload, MessageResponse>(AUTH_PATH, 'verify', false)
  const login = usePostApiService<LoginPayload, LoginResponse>(AUTH_PATH, 'signin', false)
  const recovery = usePostApiService<RecoverPayload, MessageResponse>(AUTH_PATH, 'password-recovery', false)
  const forgot = usePostApiService<ForgotPayload, MessageResponse>(AUTH_PATH, 'forgot-password', false)
  const googleLogin = usePostApiService<GoogleLoginPayload, LoginResponse>(AUTH_PATH, 'google-authentication', false)

  return { register, verification, login, forgot, recovery, googleLogin }
}

export const useAuthService = () => {
  const logout = useGetApiService<any, MessageResponse>(AUTH_PATH, LOGOUT_RESOURCE, false)
  const checkAuthentication = useGetApiService<any, AuthResponse>(AUTH_PATH, 'check-authentication', false)

  return { checkAuthentication, logout }
}

export const useSlackService = () => {
  const callback = useGetApiService<any, MessageResponse>(SLACK_PATH, 'callback')

  return { callback }
}

export const useChatService = () => {
  const chatStream = useStreamService<ChatPayload>(CHAT_PATH, 'message/stream')

  return { chatStream }
}

export const useCompanyService = () => {
  const getCompany = useGetApiService<any, GetCompanyResponse>(COMPANY_PATH)
  const updateCompany = usePatchApiService<any, MessageResponse>(COMPANY_PATH)
  const getCompanyMemories = useGetApiService<any, GetMemoriesResponse>(COMPANY_PATH)
  const deleteCompanyMemory = useDeleteApiService<any, MessageResponse>(COMPANY_PATH)

  return { getCompany, updateCompany, getCompanyMemories, deleteCompanyMemory }
}
