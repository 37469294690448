import { create } from 'zustand'

export interface User {
  id: string
  email: string
  name?: string
  companyIds: string[]
}

interface AuthState {
  isLogged: boolean
  message: string
  user?: User
  login: (user: User) => void
  logout: (message?: string) => void
}

export const useAuthStore = create<AuthState>(set => ({
  isLogged: false,
  message: '',
  user: undefined,
  login: (user: User) => set({ isLogged: true, user }),
  logout: (message?: string) => set({ isLogged: false, message: message ?? '', user: undefined }),
}))
