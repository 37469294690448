import { ReactNode } from 'react'

import { useTitleStore } from '@/store/title-store'

import { Sidebar } from './components/sidebar'

import { Avatar } from '../components/avatar'
import './ConfigurationLayout.scss'

interface ConfigurationLayoutProps {
  children: ReactNode
}

const ConfigurationLayout: React.FC<ConfigurationLayoutProps> = ({ children }) => {
  const { title } = useTitleStore()

  return (
    <div id="configuration-layout">
      <Sidebar />
      <div className="main-content">
        <nav className="navbar flex justify-between">
          <div className="breadcrumb flex-1" />
          <div className="title mx-auto flex items-center justify-center">{title ?? null}</div>
          <div className="menu flex flex-1 items-center justify-end pl-5">
            <Avatar className="mr-5" />
          </div>
        </nav>
        <div className="content">{children}</div>
      </div>
    </div>
  )
}

export default ConfigurationLayout
