export const resources = {
  en: {
    translation: {
      login: {
        title: 'Authentication',
        legend: "Don't have an account?",
        email_label: 'Email',
        email_required: 'Please input your email',
        email_placeholder: 'Email...',
        password_label: 'Password',
        password_required: 'Please input your password',
        password_placeholder: 'Password...',
        register: 'Register',
        submit: 'Authenticate',
        google_btn: 'Continue with Google',
        forgot: 'Forgot password',
      },
      forgot: {
        title: 'Forgot password',
        legend: 'Enter your email address and we will send you instructions to reset your password.',
        email_label: 'Email',
        email_required: 'Please input your email',
        email_placeholder: 'Email...',
        submit: 'Send',
      },
      password_bar: {
        bar_level_1: 'weak password',
        bar_level_2: 'weak password',
        bar_level_3: 'okay password',
        bar_level_4: 'good password',
        bar_level_5: 'strong password',
        password_must: 'Password must contain at least',
        must_characters: '12 character',
        must_uppercase: '1 uppercase',
        must_lowercase: '1 lowercase',
        must_number: '1 number',
        must_special: '1 special character e.g. !_@',
      },
      register: {
        title: 'Register',
        legend: 'Already have an account?',
        signin: 'Authenticate',
        submit: 'Register',
        name_required: 'Please input your name',
        name_label: 'Name',
        name_placeholder: 'Name...',
        email_required: 'Please input your email',
        email_not_valid: 'Email is not valid',
        email_label: 'Email',
        email_placeholder: 'Email...',
        password_label: 'Password',
        password_placeholder: 'Password...',
        password_required: 'Please input your password',
        confirmation_required: 'Please confirm your password',
        confirmation_not_match: "Password confirmation doesn't match",
        confirmation_label: 'Confirm password',
        confirmation_placeholder: 'Password confirmation...',
        google_btn: 'Continue with Google',
      },
      recovery: {
        title: 'Password Recovery',
        legend: "We've sent a verification code to your email. Please enter it below.",
        submit: 'Change',
        password_required: 'Please input your new password',
        new_password_label: 'New password',
        new_password_placeholder: 'Password...',
        confirmation_required: 'Please confirm your new password',
        confirmation_not_match: "Password confirmation doesn't match",
        confirmation_label: 'Confirm new password',
        confirmation_placeholder: 'Password confirmation...',
        title_verified: 'Password Changed! 🎉',
        legend_verified: 'You can now login.',
        login: 'Go to Login',
        resend: 'Resend Code',
      },
      verification: {
        title: 'Verification Code',
        legend: "We've sent a verification code to your email. Please enter it below.",
        title_verified: 'Code Verified! 🎉',
        legend_verified: 'You can now login.',
        login: 'Go to Login',
        resend: 'Resend Code',
      },
      callback: {
        title: 'Authenticating...',
        legend: 'Please wait while we verify your credentials. You will be redirected shortly.',
        login: 'Back to Login',
      },
      slack_callback: {
        title: 'Verifying...',
        legend: 'Please wait while we verify the slack installation. You will be redirected shortly.',
        company: 'Back to Company',
      },
      sidebar: {
        expert: 'Expert',
        diagnostic: 'Diagnostic',
        help: 'Help',
        history: 'History',
        company: 'Company',
        memories: 'Memories',
        profile: 'Profile',
        settings: 'Settings',
        logout: 'Logout',
        risk: 'Risk',
      },
      home: {
        title: 'Home',
        welcome_1: 'Welcome,',
        welcome_2: 'my name is',
        welcome_3: 'Secura',
        welcome_4: 'your Cybersecurity Assistant.',
        help_message: 'How can I help you today?',
        expert: 'Expert',
        expert_text: 'I have a question about cybersecurity and need an expert.',
        diagnostic: 'Diagnostic',
        diagnostic_text: 'I want to conduct a vulnerability assessment for my company.',
        help: 'Help',
        help_text: 'I have a question about Secura Platform.',
        security_message:
          'The conversations will be private, and the information will be secured so that only you have access.',
      },
      company: {
        title: 'Company',
        section_title: 'Manage Company',
        manage_memory: 'manage memory',
        active_users: 'Active Users',
        column_name: 'Name',
        column_email: 'Email',
        column_client: 'Client',
        search_placeholder: 'Search...',
      },
      memory: {
        title: 'Memories',
        section_title: 'Manage Memories',
        column_date: 'Date',
        column_description: 'Description',
        column_context: 'Context',
        column_type: 'Type',
        search_placeholder: 'Search...',
        column_actions: 'Actions',
        delete_title: 'Delete the memory',
        delete_description: 'Are you sure to delete this memory?',
        delete_yes: 'Yes',
        delete_no: 'No',
        context_title: 'Context',
      },
    },
  },
}
