import { SyntheticEvent } from 'react'

import { useAuthStore } from '@/store/auth-store'

interface Props {
  className?: string
}

export const Avatar = ({ className }: Props) => {
  const { user } = useAuthStore()

  const handleClick = (event: SyntheticEvent) => {
    event.preventDefault()
  }

  const firstLetter = user?.name?.[0] ?? user?.email?.[0] ?? ''
  return (
    <a
      onClick={handleClick}
      href="#"
      className={`${className} avatar bg-red flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-full text-[20px] font-medium leading-[20px] text-white hover:text-white`}
    >
      {firstLetter ?? ''}
    </a>
  )
}
