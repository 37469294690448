import { Link, useNavigate } from 'react-router-dom'
import i18n from 'i18next'

import { MenuItem } from '@/components/menu-item'
import { TagButton } from '@/components/tag-button'
import { routes } from '@/router'
import smallLogo from '@/assets/logo.png'
import verified from '@/assets/verified.png'
import company from '@/assets/company.png'
import memory from '@/assets/memory.png'
import logoutImg from '@/assets/logout.png'
import { useAuthService } from '@/api/use-service'
import { useAuthStore } from '@/store/auth-store'

export const Sidebar = () => {
  const { logout } = useAuthService()
  const { logout: storeLogout } = useAuthStore()
  const navigate = useNavigate()

  const onLogout = async () => {
    const { error } = await logout.invoke()
    if (!error) {
      storeLogout()
    }
  }
  return (
    <aside className="sidebar flex flex-col justify-start p-5">
      <Link to={routes.HOME} className="flex items-center">
        <img className="h-auto w-[30px] cursor-pointer object-contain" src={smallLogo} />
        <span className="ml-[32px] font-logo text-[16px] font-bold">
          <span className="text-primary">Secura</span> Platform
        </span>
      </Link>
      <div className="mt-8">
        <TagButton
          className="mb-2"
          src={verified}
          title={i18n.t('sidebar.expert')}
          onClick={() => navigate({ pathname: routes.EXPERT })}
        />
        {/*
        <TagButton className="mb-2" src={analyze} title={i18n.t('sidebar.diagnostic')} onClick={() => undefined} />
        <TagButton className="mb-2" src={question} title={i18n.t('sidebar.help')} onClick={() => undefined} />
        */}
      </div>
      {/*
      <ConversationHistory title={i18n.t('sidebar.history')} className="mt-8" />
      */}
      <div className="mt-auto flex flex-col">
        <MenuItem
          className="mb-1"
          src={company}
          title={i18n.t('sidebar.company')}
          onClick={() => navigate({ pathname: routes.COMPANY })}
        />
        <MenuItem
          className="mb-1"
          src={memory}
          title={i18n.t('sidebar.memories')}
          onClick={() => navigate({ pathname: routes.MEMORY })}
        />
        {/*
        <MenuItem className="mb-1" src={userImg} title={i18n.t('sidebar.profile')} onClick={() => undefined} />
        <MenuItem className="mb-1" src={gear} title={i18n.t('sidebar.settings')} onClick={() => undefined} />
        */}
        <MenuItem className="mb-1" src={logoutImg} title={i18n.t('sidebar.logout')} onClick={onLogout} />
      </div>
    </aside>
  )
}
