import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { devtools } from 'zustand/middleware'

interface TitleState {
  title: string | JSX.Element | undefined | null
  setTitle: (id: string | JSX.Element | undefined | null) => void
}

export const useTitleStore = create<TitleState>()(
  immer(
    devtools(set => ({
      title: undefined,
      setTitle: title =>
        set(state => {
          state.title = title
        }),
    })),
  ),
)
