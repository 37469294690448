import add from '@/assets/add.png'
interface Props {
  onClick: () => void
  title: string
  src: string
  className?: string
}

export const TagButton = ({ title, onClick, src, className }: Props) => (
  <button
    onClick={onClick}
    type="button"
    className={`${className ?? ''} me-2 flex w-full items-center bg-raven-black px-5 py-3 text-sm text-white hover:bg-secondary`}
  >
    <img className="h-auto w-[17px] cursor-pointer object-contain" src={src} />
    <span className="ml-5 text-[15px] font-semibold">{title}</span>
    <img className="ml-auto h-auto w-[17px] cursor-pointer object-contain" src={add} />
  </button>
)
